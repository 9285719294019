import { useState, useEffect } from "react";

const images = [
    '/slides/slide1.jpg',
    '/slides/slide2.png',
    '/slides/slide3.jpg',
];

const BannerCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        if (!isHovered) {
            const timer = setInterval(() => {
                nextSlide();
            }, 3000);
            return () => clearInterval(timer);
        }
    }, [isHovered]);

    return (
        <div
            className="relative w-full h-[20vh] sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[70vh] 2xl:h-[80vh] overflow-hidden shadow-lg bg-gray-100"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {/* Carousel wrapper */}
            <div
                className="flex transition-transform duration-700 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div key={index} className="w-full h-full flex-shrink-0 relative">
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>

            {/* Navigation buttons */}
            <button
                onClick={prevSlide}
                aria-label="Previous slide"
                className={`absolute top-1/2 left-2 md:left-4 transform -translate-y-1/2 bg-white text-gray-800 p-2 md:p-3 rounded-full shadow-md transition duration-300 ${isHovered ? "opacity-100" : "opacity-0"
                    } hover:bg-gray-200 focus:outline-none`}
            >
                ‹
            </button>
            <button
                onClick={nextSlide}
                aria-label="Next slide"
                className={`absolute top-1/2 right-2 md:right-4 transform -translate-y-1/2 bg-white text-gray-800 p-2 md:p-3 rounded-full shadow-md transition duration-300 ${isHovered ? "opacity-100" : "opacity-0"
                    } hover:bg-gray-200 focus:outline-none`}
            >
                ›
            </button>

            {/* Indicator dots */}
            <div className="absolute bottom-2 sm:bottom-4 left-0 right-0 flex justify-center space-x-2 sm:space-x-3">
                {images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        aria-label={`Go to slide ${index + 1}`}
                        className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full ${currentIndex === index ? "bg-blue-600" : "bg-gray-300"
                            } hover:bg-blue-400 transition-colors duration-300`}
                    />
                ))}
            </div>

            {/* Overlay Gradient */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/30 to-black/50 pointer-events-none"></div>
        </div>
    );
};

export default BannerCarousel;
