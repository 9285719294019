import React from "react";

const Technologies = () => {
    const techItems = [
        {
            title: "AI/ML",
            description: "Harness the power of Artificial Intelligence and Machine Learning for innovative solutions.",
            img: "./techImages/AI.jpg",
        },
        {
            title: "Java",
            description: "Versatile and robust programming language widely used for enterprise applications.",
            img: "./techImages/java.jpeg",
        },
        {
            title: "Python",
            description: "A versatile and beginner-friendly programming language for web, data science, and AI.",
            img: "./techImages/python.jpeg",
        },
        {
            title: ".NET",
            description: "Microsoft's framework for building scalable and secure applications.",
            img: "./techImages/Microsoft_.NET_logo.png",
        },        
        {
            title: "MERN Stack",
            description: "A powerful full-stack solution using MongoDB, Express.js, React, and Node.js.",
            img: "./techImages/mern.jpg",
        },
        {
            title: "PHP",
            description: "Server-side scripting language for web development.",
            img: "./techImages/php.jpg",
        },
        {
            title: "WordPress",
            description: "Content management system for building websites.",
            img: "./techImages/WordPress.avif",
        },
        {
            title: "Laravel",
            description: "A robust PHP framework for modern web applications.",
            img: "./techImages/Laravel.webp",
        },
        {
            title: "Next.js",
            description: "React framework for building server-rendered web applications.",
            img: "./techImages/nextjs.jpg",
        },
        {
            title: "JavaScript",
            description: "The backbone of modern web development.",
            img: "./techImages/javascript.jpg",
        },
        {
            title: "React Native",
            description: "A library for building user interfaces.",
            img: "./techImages/reactNative.png",
        },
        {
            title: "Node.js",
            description: "JavaScript runtime for server-side development.",
            img: "./techImages/nodejs.jpg",
        },
    ];

    return (
        <div id="tech" className="p-8 bg-gradient-to-r from-blue-50 via-blue-100 to-white">
            {/* Heading Section */}
            <div className="text-center mb-10">
                <h1 className="text-4xl font-extrabold text-gray-800">
                    Our Expertise in Cutting-Edge Technologies
                </h1>
                <p className="text-gray-600 mt-4 text-lg">
                    We provide services using the most popular technologies to build innovative solutions for web and mobile.
                </p>
            </div>

            {/* Technologies Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {techItems.map((tech, index) => (
                    <div
                        key={index}
                        className="flex items-center space-x-6 bg-white shadow-lg p-6 rounded-lg border border-gray-300 hover:shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out"
                    >
                        <img
                            src={tech.img}
                            alt={tech.title}
                            className="w-16 h-16 rounded-full border-2 border-gray-300 shadow-sm"
                        />
                        <div>
                            <h3 className="text-xl font-semibold text-gray-800">{tech.title}</h3>
                            <p className="text-gray-500 text-sm mt-2">{tech.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Technologies;
